import React from "react";
import PropTypes from "prop-types";
import kebabCase from "lodash/kebabCase";
import { graphql } from "gatsby";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import { dateConversion, isMp4 } from "../utils/functions";
import { ACTIVE_PAGE } from "../components/nav/utils";
import { MainContainer, BlogWrapper, BlogPost, BlogLink, CardTitle, TagText, CardTagButton, CardExcerptText, TagLink, CardMP4, CardImage } from "../page-components/blog/styles";

const Tags = ({ pageContext, data }: { pageContext: any, data: any }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  // const tags = data.allMarkdownRemark.group

  let tagHead = "";
  if (Number(totalCount) > 1) {
    tagHead = `${totalCount} posts tagged with "${tag}"`;
  } else {
    tagHead = `${totalCount} post tagged with "${tag}"`;
  }

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.TAGS}>
      <Seo title={""} />
      <header className="site__header">
        <h1 className="header__motto header__motto--no-margin">{tagHead}</h1>
      </header>
      <MainContainer>
        <BlogWrapper>
          {edges.map(({ node }: { node: any }) => {
            return (
              <BlogPost key={node.id}>
                <BlogLink
                  className="blog_link"
                  to={`/blog/${kebabCase(node.fields.slug)}/`}
                >
                  {isMp4(node.frontmatter.mediaSrc) ? (
                    <CardMP4>
                      <source
                        src={node.frontmatter.mediaSrc}
                        type="video/mp4"
                      />
                    </CardMP4>
                  ) : (
                    <CardImage
                      src={node.frontmatter.mediaSrc}
                      alt="img"
                      loading="lazy"
                    />
                  )}

                  <CardTitle>
                    {node.frontmatter.title}
                  </CardTitle>
                </BlogLink>
                <TagText>
                  Robotic Imaging, {dateConversion(node.frontmatter.date)}
                </TagText>
                <br />
                <TagText>
                  Tags:{" "}
                  {node.frontmatter.tags.map((tag: string, i: number) => (
                    <CardTagButton type="button" key={i}>
                      <small>
                        <TagLink to={`/tags/${kebabCase(tag)}/`}>
                          {tag}
                        </TagLink>
                        {i < node.frontmatter.tags.length - 1 ? ", " : ""}
                      </small>
                    </CardTagButton>
                  ))}
                </TagText>
                <CardExcerptText>{node.excerpt}</CardExcerptText>
              </BlogPost>
            );
          })}
        </BlogWrapper>
      </MainContainer>
    </SiteWrapper>
  );
};

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

export default Tags;

export const pageQuery = graphql`
  query ($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
            author
            date(formatString: "MMMM DD, YYYY")
            mediaSrc
          }
          excerpt
          id
        }
      }
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
