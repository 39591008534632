import React, { useEffect } from "react";

/**
 * React Hook for autoplaying videos when they intersect with the viewport
 * @param {IntersectionObserverInit} options
 * @returns React ref for intersection autoplay videos
 */
const useVideoAutoPlayback = (options?: IntersectionObserverInit) => {
  const videoRef = React.useRef<HTMLVideoElement>(null);

  const callback: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Play video if it is intersecting with viewport
        videoRef.current?.play();
      } else if (!videoRef.current?.paused) {
        // Pause video if it is not intersecting and playing, but
        // simply calling pause() can cause errors because it interrupts play()
        // So we call play() again (which doesn't interrupt ongoing play() calls) and then call
        // pause() after the play() promise finishes
        const promise = videoRef.current?.play();
        if (promise !== undefined)
          promise.then(() => videoRef.current?.pause());
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);
    let observerRef: HTMLVideoElement | null = null;

    if (videoRef.current) {
      observer.observe(videoRef.current);
      observerRef = videoRef.current;
    }

    return () => {
      if (observerRef) observer.unobserve(observerRef);
    };
  }, [videoRef, options]);

  return videoRef;
};

export default useVideoAutoPlayback;
