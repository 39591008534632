/**
 * Function that takes url string of some Firebase storage hosted media
 * and returns whether or not it is an mp4
 * @param url Firebase storage hosted media url
 * @returns boolean
 */
export function isMp4(url: string) {
  if (!url) return false;

  const urlArr = url.split("?alt=media&token=");
  return urlArr[0].endsWith(".mp4");
}

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const dateConversion = (date: string) => {
  const baselineDate = "May 31, 2023"; // Date to which the most recent outdated post will be set to
  const offsetDate = "April 12, 2023"; // Date from which posts will be calculated to be outdated or not

  const currentDifference = Date.parse(offsetDate) - Date.parse(date); // Difference between offsetDate and given date. Outdated dates will be >= 0.

  if (currentDifference < 0) {
    // Given date is more recent than offset date, so no change
    return date;
  } else {
    // Given date is outdated, so add conversionDifference to date before returning
    const conversionDifference =
      Date.parse(baselineDate) - Date.parse(offsetDate);
    return new Date(Date.parse(date) + conversionDifference).toLocaleString(
      "en-us",
      { month: "long", day: "numeric", year: "numeric" }
    );
  }
};

export const formatDateFromYMD = (dateStr: string) => {
  const date = new Date(dateStr);
  return date.toLocaleDateString("en-us", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};
