import { styled } from "@mui/material";
import { Link } from "gatsby";
import IntersectionAutoPlayVideo from "../../components/IntersectionAutoPlayVideo";
import { breakpoint } from "../../components/styled-components/Breakpoints/breakpoints";

/* Styled Components for the Blog page */

export const MainContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 100px;
  justify-content: center;
  width: 100%;

  ${breakpoint(
    "small-",
    `
    align-items: center;
  `
  )}
`;

// Styling for the container holding newsletter signup + filter dropdown
export const ActionBar = styled("div")`
  display: flex;
  flex-direction: row;
  width: 90vw;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 1100px) {
    flex-direction: column;
    gap: 20px;
    max-width: 320px;
    width: 100%;
    min-height: 50px;
  }
`;

export const BlogWrapper = styled("div")`
  display: grid;
  /* width: 100%; */
  gap: 10px;
  justify-content: center;
  gap: 20px;
  padding: 4vw;

  ${breakpoint(
    "small+",
    `
    grid-template-columns: repeat(1, 1fr);
    padding: 50px 80px;

  `
  )}
  ${breakpoint(
    "medium+",
    `
    grid-template-columns: repeat(2, 1fr);
    padding: 50px 100px;

  `
  )}
   ${breakpoint(
    "large+",
    `
    grid-template-columns: repeat(3, 1fr);
    padding: 50px 120px;

  `
  )}
   ${breakpoint(
    "xlarge+",
    `
    grid-template-columns: repeat(4, 1fr);
    padding: 50px 150px;

  `
  )}
`;

export const BlogPost = styled("div")`
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-self: center;
  border-radius: 25px;
  padding: 10px;
  width: 90%;
  min-width: 250px;

  ${breakpoint(
    "small-",
    `
    width: 300px;
  `
  )}
`;

export const BlogLink = styled(Link)`
  color: #000;

  &:hover {
    color: #fff;
  }
`;

export const TagLink = styled(Link)`
  color: #000;

  > h2 {
    color: black;
    font-size: 13px;
  }
`;

export const CardImage = styled("img")`
  border-radius: 25px;
  width: 100%;
  height: 200px;
  object-fit: cover;
  padding: 0px 0px 10px;

  &:hover {
    box-shadow: 0 0 5px 1px $color__primary;
  }

  .missing-media {
    object-fit: contain;
  }
`;

export const CardMP4 = styled(IntersectionAutoPlayVideo)`
  border-radius: 25px;
  width: 100%;
  height: 200px;
  object-fit: cover;
  padding: 0px 0px 10px;

  &:hover {
    box-shadow: 0 0 5px 1px #ffb310;
  }

  .missing-media {
    object-fit: contain;
  }
`;

export const CardTitle = styled("p")`
  margin: 10px 0px 5px 0px;
  color: black;
  font-size: 20px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: normal;
`;

export const TagText = styled("p")`
  color: grey;
  font-size: 13px;
  line-height: normal;
  letter-spacing: normal;
  padding: 0px;
`;

export const CardTagButton = styled("button")`
  background-color: #ffb310;
  border-radius: 12px;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right: 5px;
  margin-bottom: 5px;

  &_tag {
    font-size: 12px;
    margin-left: 5px;
  }
`;

export const CardExcerptText = styled("span")`
  color: #000;
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 5px;
  line-height: 18px;
  letter-spacing: normal;
`;

export const BlogHeaderImage = styled("img")`
  border-radius: 25px;
  width: 100%;
  object-fit: cover;
  height: 400px;

  &:hover {
    box-shadow: 0 0 5px 1px $color__primary;
  }

  &__missing-media {
    object-fit: contain;
  }
`;

export const BlogHeaderVideo = styled("video")`
  border-radius: 25px;
  width: 100%;
  object-fit: cover;
  height: 400px;

  &:hover {
    box-shadow: 0 0 5px 1px $color__primary;
  }

  &__missing-media {
    object-fit: contain;
  }
`;
